import { Card, Col, Divider, Row, Modal, Button } from "antd";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";

const { Meta } = Card;

const Attribute = {
  NAME: "Name",
  PRONOUNS: "Pronouns",
  PLEDGE_CLASS: "Pledge Class",
  GRAD_CLASS: "Graduating Class",
  EXEC_ROLE: "Are you on Exec? If so, what role?",
  MAJOR: "Field(s) of Study",
  BIO: "Bio -- Include professional, academic, and personal interests! Write in third person, <500 characters.",
  EXPERIENCE: `Work Experience -- Include all relevant experiences, separate each by semicolon. Ex: CS Section Leader; Analyst Intern @ Company; Research @ Lab`,
  LINKEDIN: "LinkedIn URL",
  IMAGE_URL:
    "Headshot -- We'll take new ones at the beginning of Fall Quarter but please upload one now! Can be a casual photo for right now, but it must only have you in it.",
};

const EXEC_ROLES = [
  "Co-Lead (President)",
  "Co-Lead (Chairperson)",
  "VP Administration",
  "VP Finance",
  "VP Recruitment",
  "VP Membership",
  "VP Alumni Relations",
  "VP Professional Development",
  "Social Chair",
];

const Brotherhood = () => {
  const [members, setMembers] = useState([]);
  const [activeModalMember, setActiveModalMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("members.csv")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((csvData) => {
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            console.log("Parsed data:", results.data);
            setMembers(results.data);
          },
          error: (error) => {
            console.error("Papaparse error:", error);
            setError("Error parsing CSV data");
          },
        });
      })
      .catch((e) => {
        console.error("Error fetching CSV:", e);
        setError(e.message);
      });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const getCardFromMember = (member) => {
    return (
      <Card
        hoverable={true}
        bordered={false}
        onClick={() => {
          setActiveModalMember(member);
          setIsModalOpen(true);
        }}
        cover={
          <img
            // className="max-h-32 md:max-h-200"
            style={{ objectFit: "cover", height: 200 }}
            alt={member[Attribute.NAME]}
            src={`./people/${member[Attribute.NAME]}.jpg`}
          />
        }
      >
        <Meta
          style={{ textAlign: "center" }}
          title={member[Attribute.NAME]}
          description={member[Attribute.EXEC_ROLE] !== "Not on Exec" && member[Attribute.EXEC_ROLE]}
        />
      </Card>
    );
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {activeModalMember && (
        <Modal
          width="80%"
          title={
            <span>
              <span className="text-xl font-bold">{activeModalMember[Attribute.NAME]} </span>
              <span className="text-gray-500">({activeModalMember[Attribute.PRONOUNS]})</span>
            </span>
          }
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="flex flex-col md:flex-row gap-4 py-4">
            <img
              className="m-auto md:m-0 w-60 h-60 rounded-xl object-cover object-center"
              alt={activeModalMember[Attribute.NAME]}
              src={`./people/${activeModalMember[Attribute.NAME]}.jpg`}
              style={{
                aspectRatio: "1 / 1",
              }}
            />
            <div className="flex flex-col gap-2">
              <div>
                {" "}
                <h1 className="font-bold">
                  {activeModalMember[Attribute.PLEDGE_CLASS]} Pledge Class
                  {activeModalMember[Attribute.EXEC_ROLE] !== "Not on Exec" && (
                    <span className="text-sm text-gray-500">
                      {" "}
                      ({activeModalMember[Attribute.EXEC_ROLE]})
                    </span>
                  )}
                </h1>
                <h1 className="font-bold">
                  Class of {activeModalMember[Attribute.GRAD_CLASS]} |{" "}
                  {activeModalMember[Attribute.MAJOR]}
                </h1>
              </div>

              <p>{activeModalMember[Attribute.BIO]}</p>
              <h1 className="font-bold">Experience:</h1>
              <ul className="list-disc list-inside">
                {activeModalMember[Attribute.EXPERIENCE].split(";").map((experience, index) => (
                  <li key={index}>{experience}</li>
                ))}
              </ul>
              <Button href={activeModalMember[Attribute.LINKEDIN]}>LinkedIn</Button>
            </div>
          </div>
        </Modal>
      )}
      <section className="w-full px-4 lg:px-40 pt-20 pb-16">
        <h1
          className="text-center text-4xl font-Raleway font-extrabold mb-8 bg-clip-text"
          style={{ color: "#232323", lineHeight: "1.2" }}
        >
          Brotherhood
        </h1>
        {/* <p
          className="description font-bold text-center font-Raleway px-4"
          style={{ color: "#585858", fontSize: "18px", width: "80%" }}
        >
          Check out where our alumni are now.
        </p> */}
        <h2 className="font-Raleway text-3xl bg-clip-text" style={{ color: "#232323" }}>
          Executive Committee
        </h2>
        <div className="pt-8">
          <Row type="flex" gutter={[16, 16]}>
            {members.length > 0 ? (
              EXEC_ROLES.map((role) =>
                members
                  .filter((member) => member[Attribute.EXEC_ROLE] === role)
                  .map((member, index) => (
                    <Col key={index} xs={12} sm={8} md={6} lg={6} xl={6} type="flex">
                      {getCardFromMember(member)}{" "}
                    </Col>
                  ))
              )
            ) : (
              <Col span={24}>
                <p>No members data available.</p>
              </Col>
            )}
          </Row>
        </div>
        <Divider />
        <h2 className="font-Raleway text-3xl bg-clip-text" style={{ color: "#232323" }}>
          Active Members
        </h2>
        <div className="pt-16 lg:pt-8">
          <Row type="flex" gutter={[16, 16]}>
            {members.length > 0 ? (
              members
                .filter((member) => member[Attribute.EXEC_ROLE] === "Not on Exec")
                .sort((a, b) => {
                  const lastNameA = a[Attribute.NAME].split(" ").slice(-1)[0].toLowerCase();
                  const lastNameB = b[Attribute.NAME].split(" ").slice(-1)[0].toLowerCase();
                  return lastNameA.localeCompare(lastNameB);
                })
                .map((member) => (
                  <Col xs={12} sm={8} md={6} lg={6} xl={6} type="flex">
                    {getCardFromMember(member)}
                  </Col>
                ))
            ) : (
              <Col span={24}>
                <p>No members data available.</p>
              </Col>
            )}
          </Row>
        </div>
      </section>
    </>
  );
};

export default Brotherhood;
